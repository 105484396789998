import React from "react"
import * as S from "./styled"

import loadable from '@loadable/component'
//import Navigation from "../Navigation"
const Navigation = loadable(() => import('../Navigation'))

const Menu = () => (
    <S.Bg>
        <S.WrapperHeader>
        <Navigation />
        </S.WrapperHeader>
    </S.Bg>
)

export default Menu
