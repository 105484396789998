import styled from "styled-components"
import media from "styled-media-query"
import contactImg from "../../images/contact-image.png"

export const Section = styled.section`
    // margin-top: 100px;
` 
export const Title = styled.h2`
  margin-top: 20px;
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.color ? "#455A64"  : props.theme.colors.white};
  font-weight: 600;
  line-height: 20px;
  > hr {
    width: 280px;
    border: 0;
    height: 0;
    box-shadow: 0 0 5px 1px ${props => props.theme.colors.success};
    opacity: 0.7;
    margin-top: 1px;
  }
  ${media.lessThan("small")`
    text-align: center;
    font-size: 25px;
    > hr {
    width: 180px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
  }
  `}
  ${media.between("medium", "large")`
    font-size: 35px;
    > hr {
    width:220px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
    }
    `}
`

export const Paragraph = styled.p`
    margin-top: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    color: ${props => props.theme.colors.white};
    span {
      font-weight: bold;
    }
`
export const ParagraphContact = styled.p`
    margin-top: 20px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    color: #000;
    span {
      font-weight: bold;
    }
`

export const SectionOne = styled.div`
    background-image: linear-gradient(to bottom, rgba(36,129,197,0.7) 0%,rgba(36,129,197,0.7) 100%), url(${contactImg});
    background-size: cover;
    background-position: center;
    padding: 100px 0 200px 0;

    ${media.lessThan("small")`
      text-align: center;
      padding: 0;
      .mobile-content {
        flex-direction: column;
        align-items: center;
      }
      .mobile-image {
        padding: 0 20px 80px 20px;
      }
    `}
    ${media.between("medium", "large")`
      padding: 50px 0 100px 0;
    `}
`

export const TitleTwo = styled.h2`
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`

export const ParagraphTwo = styled.p`
    margin-top: 36px;
    font-family: Josefin Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    padding: 30px 0;
    color: ${props => props.theme.colors.subtitle};
`

export const WrapperCardOne = styled.div`
  margin: 50px 0px;
`
export const WrapperCardTwo = styled.div`
  margin: 150px 0px;
`
export const Wrapper = styled.div`
  ${media.between("medium", "large")`
    margin: 0 50px;
  `}
  ${media.lessThan("small")`
    margin: 0 30px;
  `}
`