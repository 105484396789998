import React from "react"
import Layout from "../components/Layout"
import Menu from "../components/Menu"
import ContactContent from "../components/ContactContent"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const Contact = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Contact" />
      {/* <Menu/> */}
      <ContactContent/>
    </Layout>
  </ThemeProvider>
)

export default Contact