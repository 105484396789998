import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import ContactContentImg from "../ContactContentImg"
import Contact from "../Contact"
import Menu from "../Menu"
import Solutions from "../Solutions"

const ContactContent = ({ t, i18n }) => (
  <>
    <S.Section>
      <S.SectionOne>
        <Menu/>
        <S.Wrapper>
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-flex is-justify-content-center is-flex-direction-column">
                <S.Title>{t("navbar.contact")}</S.Title>
{/*                 <div className="is-flex is-justify-content-space-between mt-6 mobile-content">
                  <S.Paragraph>
                    {t("contact.cityOne")}<br/>
                    {t("contact.countryOne")}
                    <S.ParagraphContact>
                      {t("contact.PhoneOne")}<br/>
                      {t("contact.emailOne")}
                    </S.ParagraphContact>
                  </S.Paragraph>  
                  <S.Paragraph>
                    {t("contact.cityTwo")}<br/>
                    {t("contact.countryTwo")}
                    <S.ParagraphContact>
                      {t("contact.PhoneTwo")}<br/>
                      {t("contact.emailOne")}
                    </S.ParagraphContact>
                  </S.Paragraph>

                </div> */}
              <div className="mt-6">
                <div className="columns is-desktop">
                  <div className="column">
                    <Contact/>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </S.Wrapper>
      </S.SectionOne>
    </S.Section>

    <S.Section className="mt-6">
      <div className="container">
        <div className="columns is-desktop">
          <S.Title color>FAQ</S.Title>
          <Solutions/>
        </div>
      </div>
    </S.Section>

  </>
)

export default withTrans(ContactContent)