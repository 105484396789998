import styled from "styled-components"
import media from "styled-media-query"

export const Bg = styled.div`
  /* background-color: ${props => props.theme.colors.primary}; */
  height: 120px;
`

export const WrapperHeader = styled.div`
  ${media.lessThan("small")`
    display: none;
  `}
`
